// import logo from './logo.svg';
// import './App.css';

import React from 'react';
import {BrowserRouter, HashRouter, Routes,Route} from 'react-router-dom';
import Home from './layout/Home';
import About from './layout/About';
import Contact from './layout/Contact';

import Photo from './layout/Photo';
import Video from './layout/Video';

import Menu from './layout/Menu';
import Review from './layout/Review';

import PostReview from './layout/PostReview';
import MyReview from './layout/MyReview';



function App() {
  return (
    <>
      {/* <BrowserRouter> */}
      <HashRouter>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/:category_slug' element={<Menu />} />
            

            <Route exact path='/photo' element={<Photo />} />
            <Route exact path='/video' element={<Video />} />
            <Route exact path='/review' element={<Review />} />

            <Route exact path='/rating/postreview' element={<PostReview />} />
            <Route exact path='/rating/myreview/:review_id' element={<MyReview />} />
            

            <Route exact path='/contact' element={<Contact />} />
        </Routes>    
        </HashRouter>
    {/* </BrowserRouter> */}
    </>
  );
}

export default App;
