import React from "react";
import ClipLoader from "react-spinners/ClipLoader"; // Or any spinner from react-spinners

const Spinner = () => {
  const spinnerStyle = {
    position: "fixed",
    top: "10%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 9999,
  };

  return (
    // <div style={spinnerStyle}>
    //   <ClipLoader size={50} color="#36d7b7" />
    // </div>
    <div className="spinner">
        <div></div>
    </div>
  );
};

export default Spinner;