
import React, { useState }  from 'react';
import {Link} from 'react-router-dom';



function Footer() {

  let [menulist,setMenulist]=useState([]);
  
  async function getMenus() {

      fetch('https://vasundharahotel.com/boss/api/category')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setMenulist(data.category);

      });

  }

  let [reviewqr,setReviewqr]=useState("");
  async function getReviewQr() {
    fetch('https://vasundharahotel.com/boss/api/showreviewqr')
      .then((res) => {
        return res.json();
      })
      .then((data) => {

        console.log("QR IMAGE==========>",data)
        
        setReviewqr(data);

      });
  }
  React.useEffect(()=>{

    getReviewQr();

    getMenus();

  },[]);

   return (
      <>

        <footer className='ft_bg'>
          <div class="container">
              <div class="row">
                <div class="col-xl-4 col-lg-6">
                    <div class="logo-white">
                      <a href="index.html"><img alt="logo-white" src="assets/img/logo-white.png" class="img-fluid" /></a>
                      <div className='py-1 text-white'>Regular: Monday - Sunday</div>
                      <div className='py-1 text-white'>Time:   11:00AM - 11:00PM</div>
                      <div className='py-1 text-white text-center'>
                        <img src={"https://vasundharahotel.com/boss/public/qrcodes/"+reviewqr.qr_image} style={{width: '150px'}}/>
                      </div>
                      {/* <img alt="tripa" src="assets/img/tripa.png" />
                      <h6>5 star rated on TripAdvisor</h6> */}
                      
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-6">
                    <div class="link-about">
                      <h3>About</h3>
                      <ul>
                          <li><i class="fa-solid fa-angle-right"></i><Link to="/about">About</Link></li>
                          <li><i class="fa-solid fa-angle-right"></i><Link to="/photo">Gallery</Link></li>
                          <li><i class="fa-solid fa-angle-right"></i><Link to="/video">Video</Link></li>
                          <li><i class="fa-solid fa-angle-right"></i><Link to="/contact">Contact</Link></li>
                      </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-6">
                    <div class="link-about">
                      <h3>Menu</h3>
                      <ul>
                          {menulist.map((e)=>
                            <li key={e.category_id}><i class="fa-solid fa-angle-right"></i><Link to={"/"+e.category_slug}>{e.category_name}</Link></li>
                          )}
                          {/* <li><i class="fa-solid fa-angle-right"></i><a href="menu-1.html">Burgers</a></li>
                          <li><i class="fa-solid fa-angle-right"></i><a href="menu-1.html">Coctails</a></li>
                          <li><i class="fa-solid fa-angle-right"></i><a href="menu-1.html">Bar B Q</a></li>
                          <li><i class="fa-solid fa-angle-right"></i><a href="menu-1.html">Desserts</a></li> */}
                      </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <div class="link-about">
                      <h3>Newsletter</h3>
                      {/* <p>Get recent news and updates.</p> */}
                      <form class="footer-form">
                          <input type="text" name="Enter Your Email Address" placeholder="Enter Your Email Address..." />
                          <button type="button" class="button">Subscribe</button>
                      </form>
                    </div>
                </div>
              </div>
              <div class="footer-bootem">
                <h6>© 2024 Vasundhara Hotel and Restaurant.  Design &amp; Developed By <a href="https://www.cheapwebguru.com/" target='_blank'>CHEAP WEB GURU SOLUTIONS</a></h6>
                <div class="header-social-media">
                    <a href="#"><i class="fa-brands fa-square-facebook fa-fw"></i></a>
                    <a href="#"><i class="fa-brands fa-square-twitter fa-fw"></i></a>
                    <a href="#"><i class="fa-brands fa-square-instagram fa-fw"></i></a>
                    <a href="#"><i class="fa-brands fa-square-youtube fa-fw"></i></a>   
                </div>
              </div>
          </div>
        </footer>

        {/* progress */}
        <div id="progress">
              <span id="progress-value"><i class="fa-solid fa-arrow-up"></i></span>
        </div>

        {/* Bootstrap Js */}
        <script src="assets/js/bootstrap.min.js"></script>
        <script src="assets/js/owl.carousel.min.js"></script>
        {/* fancybox */}
        <script src="assets/js/jquery.fancybox.min.js"></script>
        <script src="assets/js/custom.js"></script>

        {/* Form Script */}
        <script src="assets/js/contact.js"></script>
        <script type="text/javascript" src="assets/js/sweetalert.min.js"></script>


      </>
    );
  }
  
  export default Footer;
  