import React, { useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ReactStars from "react-rating-stars-component";

import $ from 'jquery';

import ImageCropModal from './ImageCropModal';

import axios from 'axios';

import FooterReview from '../inc/FooterReview';

const customarsay = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function MyReview() {
   let param=useParams();
   let [rating,setRating]=useState("");
   let [name,setName]=useState("");
   let [description,setDescription]=useState("");
   // let [photo,setPhoto]=useState(null);

   const logocss={
      width: '80%'
   };

      const [loading, setLoading] = React.useState(true);

      async function getPreloader() {

         const formData = new FormData();
         formData.append('review_id',atob(param.review_id));
      
         // Send the image to the server
         const response = await axios.post('https://vasundharahotel.com/boss/api/showmyreview', formData);
      
         console.log('Uploaded image URL:', response.data.url);
         console.log("Review_id",response.data.review_id);
         setReview(response.data.obj);

         setRating(response.data.obj.review_star);

         console.log("====???",rating)



         // Simulating data fetch
         const timer = setTimeout(() => setLoading(false), 1000);
         
         return () => clearTimeout(timer); // Cleanup timer
      }

    //   const ratingChanged = (newRating) => {
    //      console.log(newRating);
    //      setRating(newRating);
    //      $(".ratingstar").removeClass('border-danger').addClass('border-secondary');
    //   };

      let [review,setReview]=useState("");

      async function showmyreview_() {

            try {
               const formData = new FormData();
               formData.append('review_id',atob(param.review_id));
           
               // Send the image to the server
               const response = await axios.post('https://vasundharahotel.com/boss/api/showmyreview', formData);
           
               console.log('Uploaded image URL:', response.data.url);
               console.log("Review_id",response.data.review_id);
               setReview(response.data.obj);
               setRating(response.data.obj.review_star);
               console.log("====???",rating);
               
             } catch (error) {
               console.error('Error uploading the image:', error);
               alert('Failed to upload the image.');
             }
      }


      useEffect(() => {
         showmyreview_();
         getPreloader();
         
      },[]);
      return (
      <>
      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>
      :

      

      <div>

        <header className="one">
        <div className="bottom-bar ">
        <div className="container">
              <div className="row align-items-center">
                  <div className="col-xl-12 text-center pt-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                          {/* <Link to="/"> */}
                              <img alt="logo" style={logocss} src="assets/img/logo.png" />
                          {/* </Link> */}
                        </div>
                        
                    </div>
                  </div>
                  
              </div>
        </div>  
        </div>
        

       
      </header>


<section class="gap no-top pt-4">
   <div class="container">
      <div class="heading-two" style={{textAlign: 'center'}}>
         <h4>My Review</h4>
         <div class="line"></div>
      </div>
      <div class="row">
         <div class="col-xl-12 col-lg-12 py-0" style={{textAlign: 'center'}}>
            <div class="chef_">
               {/* <img alt="cook chef" src="assets/img/chef-1.png" /> */}
               {/* <div class="chef-text"> */}
                  {/* <div>
                     <span>Dessert Specialist</span>
                     <h3>Somnath Dutta</h3>
                  </div> */}
                    <div className='ratingstar align-items-center justify-content-between__' style={{textAlign: 'center'}}>
                    <ReactStars
                    
                        value={rating}
                        style={{textAlign: 'center'}}
                        count={5}
                        
                        size={70}
                        edit={false}
                        // isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        // halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        color="#949191"
                        activeColor="#ffd700"
                        label="Rating"
                       
                    />
                     {/* (Under Processing) */}
                    </div>

               {/* </div> */}
            </div>
         </div>

         <div class="col-xl-12 col-lg-12 py-3">
            <label className='small'>Review Name</label>
            <div>{review.review_name}</div>
         </div>

         <div class="col-xl-12 col-lg-12 py-3">
            <label className='small'>Review Text</label>
            <div>{review.review_text}</div>
         </div>
        
        {review.review_image!=null?
         <div class="col-xl-12 col-lg-12 py-2">
            <label className='small'>Review Photo</label>
            <img src={"https://vasundharahotel.com/boss/storage/app/public/uploads/"+review.review_image} alt="Cropped" style={{ marginTop: 0, width: '100%' }} />
         </div>
         :
         ''
         }

         {/* <div class="col-xl-12 col-lg-12 pt-4" style={{textAlign: 'center'}}>
            <input type='button' className='btn btn-lg btn-success' value="Post Review"/>
         </div> */}

      </div>
   </div>
</section>


 


        {/* <Footer /> */}
        <FooterReview />

        </div>
}
      </>
    );
  }
  
  export default MyReview;
  