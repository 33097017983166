
import React, { useState, useEffect }  from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


import ReactStars from "react-rating-stars-component";


const customarsay = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  
function PageReview() {

  let [reviewlist,setReviewlist]=useState([]);
  
  async function getReview() {

      fetch('https://vasundharahotel.com/boss/api/getlimitreview')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setReviewlist(data.obj);

        console.log("review list >>>>>>>> ",data.obj)

      });

  }
  useEffect(()=>{

    getReview();

  },[]);

   return (
      <>
      
<section class="gap">
   <div class="container">
      <div class="row">
         <div class="col-xl-6">
            <div class="heading">
               <span>Testimonials & Reviews</span>
               <h2>Our Customar Feedbacks</h2>
            </div>
            <div className='testimonialWrap'>
            <Carousel responsive={customarsay} showDots arrows={false}>

                {reviewlist.map((e)=>
                <div class="bratlee-hamint" key={e.review_id}>
                     <p>{e.review_text}</p>
                     <div class="d-flex align-items-center mt-4">
                        <h4>{e.review_name}</h4>
                        <div className='star'>
                        <ReactStars
                            value={e.review_star}
                            style={{textAlign: 'center'}}
                            count={5}
                            
                            size={25}
                            edit={false}
                            // isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            // halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            color="#949191"
                            activeColor="#ffd700"
                            label="Rating"
                        />
                        </div>

                     </div>
                     <img alt="quote" class="quote" src="assets/img/quote.png" />
               </div>
               )}

               {/*  <div class="bratlee-hamint">
               <p>A good restaurant is like a vacation; it transports you, and it becomes a lot more than just about the food. All great deeds and all great thoughts</p>
               <div class="d-flex align-items-center mt-4">
                  <h3>Aritra Chakraborty</h3>
                  <ul class="star">
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                        <li><i class="fa-solid fa-star"></i></li>
                     </ul>
               </div>
               <img alt="quote" class="quote" src="assets/img/quote.png" />
               </div>
              <div class="bratlee-hamint">
                  <p>A good restaurant is like a vacation; it transports you, and it becomes a lot more than just about the food. All great deeds and all great thoughts</p>
                  <div class="d-flex align-items-center mt-4">
                     <h3>Dilip Mitra</h3>
                     <ul class="star">
                           <li><i class="fa-solid fa-star"></i></li>
                           <li><i class="fa-solid fa-star"></i></li>
                           <li><i class="fa-solid fa-star"></i></li>
                           <li><i class="fa-solid fa-star"></i></li>
                           <li><i class="fa-solid fa-star"></i></li>
                        </ul>
                  </div>
                  <img alt="quote" class="quote" src="assets/img/quote.png" />
               </div> */}

          
               


            </Carousel>
            </div>
            
         </div>
         <div class="col-xl-6">
            <div class="bratlee-img">
               <img alt="bratlee-hamin2" class="bratlee-hamint-2" src="assets/img/bratlee-hamint-2.jpg" /> 
               <img alt="bratlee-hamint" class="bratlee-hamint-1" src="assets/img/bratlee-hamint-1.jpg" />
               <img alt="bratlee-hamin3" class="bratlee-hamint-3" src="assets/img/bratlee-hamint-3.jpg" />
            </div>
         </div>
      </div>
   </div>
</section>
</>

);
}

export default PageReview;