
import React, { useState }  from 'react';
import {Link} from 'react-router-dom';



function FooterReview() {

  let [menulist,setMenulist]=useState([]);
  
  async function getMenus() {

      fetch('https://vasundharahotel.com/boss/api/category')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setMenulist(data.category);

      });

  }
  React.useEffect(()=>{

    getMenus();

  },[]);

   return (
      <>
        <footer className='ft_bg'>
          <div class="container">
              
              <div class="footer-bootem py-1" style={{marginTop: '0px'}}>
                <div className='fs-6'>© 2024 Vasundhara Family Restaurant.</div>
                <div className='fs-6'>Powered By <a href="https://www.cheapwebguru.com/" target='_blank'>CHEAP WEB GURU SOLUTIONS</a></div>
                <div class="header-social-media">
                    <a href="#"><i class="fa-brands fa-square-facebook fa-fw"></i></a>
                    <a href="#"><i class="fa-brands fa-square-twitter fa-fw"></i></a>
                    <a href="#"><i class="fa-brands fa-square-instagram fa-fw"></i></a>
                    <a href="#"><i class="fa-brands fa-square-youtube fa-fw"></i></a>   
                </div>
              </div>
          </div>
        </footer>
        {/* progress */}
        <div id="progress">
              <span id="progress-value"><i class="fa-solid fa-arrow-up"></i></span>
        </div>

        {/* Bootstrap Js */}
        <script src="assets/js/bootstrap.min.js"></script>
        <script src="assets/js/owl.carousel.min.js"></script>
        {/* fancybox */}
        <script src="assets/js/jquery.fancybox.min.js"></script>
        <script src="assets/js/custom.js"></script>

        {/* Form Script */}
        <script src="assets/js/contact.js"></script>
        <script type="text/javascript" src="assets/js/sweetalert.min.js"></script>


      </>
    );
  }
  
  export default FooterReview;
  