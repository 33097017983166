import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';

import { Gallery } from "react-grid-gallery";

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import { Fancybox } from "@fancyapps/ui";

import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Video = () => {

   let [videos,setVideos]=useState([]);

   const [loading, setLoading] = React.useState(true);

   function getPreloader() {
      // Simulating data fetch
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer); // Cleanup timer
   }

   async function getVideos() {

      fetch('https://vasundharahotel.com/boss/api/showvideos_api')
      .then((res) => {
        return res.json();
      })
      .then((data) => {

         setVideos(data.obj);

         

      });

      console.log("=====>",videos)

   }

   useEffect(() => {

      getVideos();

      getPreloader();

      
   },[]);

    
    return (
      <>
      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>

      :

      <div>
         <Header />
         <section class="banner" style={{backgroundImage: "url(assets/img/background.png)"}}>
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-lg-7">
                     <div class="title-area-data">
                     <h2>Videos</h2>
                     <p>A magical combination that sent aromas to the taste buds</p>
                     </div>
                     {/* <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Photo</li>
                     </ol> */}
                  </div>
                  <div class="col-lg-5">
                     <div class="row">
                        <div class="col-6">
                           <div class="title-area-img">
                              <img alt="title-area-img" src="assets/img/title-area-img-1.jpg" />
                              <img alt="pata" class="pata" src="assets/img/pata.png" />
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="title-area-img two">
                              <img alt="title-area-img" src="assets/img/title-area-img-2.jpg" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <ol class="breadcrumb" style={{marginLeft: '10px'}}>
            <li class="breadcrumb-item">
               <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
            </li>
            {/* <li class="breadcrumb-item active" aria-current="page">Menu</li> */}
            <li class="breadcrumb-item text-white" aria-current="page">Videos</li>
         </ol>


         <section class="gap_"> 
            <div class="container">
            <div class="row g-4 py-4">
               {videos.map((e)=>
               <div class="col-lg-4 col-md-6 wow fadeInUp p-2" data-wow-delay="0.1s">
                  <div role="button" class="team-item position-relative rounded overflow-hidden">
                     <div class="overflow-hidden">
                        <a data-fancybox href={"https://www.youtube.com/watch?v="+e.video_link_id}><img class="img-fluid" src={"https://img.youtube.com/vi/"+e.video_link_id+"/0.jpg"} alt="" /></a>
                     </div>
                     {/* <div class="team-text bg-light px-2 py-3">
  
                     </div> */}
                  </div>
               </div>
               )}
            </div>
            </div>
         </section>

        <Footer />
        </div>
}
      </>
    );
  }
  
  export default Video;
  