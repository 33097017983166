import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';

import { Gallery } from "react-grid-gallery";

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// import renderPhoto from '../randerPhoto';

// const photos = [
//    {
//      src: "/image1_800x600.jpg",
//      width: 800,
//      height: 600,
//      srcSet: [
//        { src: "/image1_400x300.jpg", width: 400, height: 300 },
//        { src: "/image1_200x150.jpg", width: 200, height: 150 },
//      ],
//    },
//    {
//      src: "/image2_1600x900.jpg",
//      width: 1600,
//      height: 900,
//      srcSet: [
//        { src: "/image2_800x450.jpg", width: 800, height: 450 },
//        { src: "/image2_400x225.jpg", width: 400, height: 225 },
//      ],
//    },
//    {
//       src: "/image1_800x600.jpg",
//       width: 800,
//       height: 600,
//       srcSet: [
//         { src: "/image1_400x300.jpg", width: 400, height: 300 },
//         { src: "/image1_200x150.jpg", width: 200, height: 150 },
//       ],
//     },
//     {
//       src: "/image1_800x600.jpg",
//       width: 800,
//       height: 600,
//       srcSet: [
//         { src: "/image1_400x300.jpg", width: 400, height: 300 },
//         { src: "/image1_200x150.jpg", width: 200, height: 150 },
//       ],
//     },
//     {
//       src: "/image1_800x600.jpg",
//       width: 800,
//       height: 600,
//       srcSet: [
//         { src: "/image1_400x300.jpg", width: 400, height: 300 },
//         { src: "/image1_200x150.jpg", width: 200, height: 150 },
//       ],
//     },
//     {
//       src: "/image1_800x600.jpg",
//       width: 800,
//       height: 600,
//       srcSet: [
//         { src: "/image1_400x300.jpg", width: 400, height: 300 },
//         { src: "/image1_200x150.jpg", width: 200, height: 150 },
//       ],
//     },
//     {
//       src: "/image1_800x600.jpg",
//       width: 800,
//       height: 600,
//       srcSet: [
//         { src: "/image1_400x300.jpg", width: 400, height: 300 },
//         { src: "/image1_200x150.jpg", width: 200, height: 150 },
//       ],
//     },
//     {
//       src: "/image1_800x600.jpg",
//       width: 800,
//       height: 600,
//       srcSet: [
//         { src: "/image1_400x300.jpg", width: 400, height: 300 },
//         { src: "/image1_200x150.jpg", width: 200, height: 150 },
//       ],
//     },
    
//  ];

// let photos = [];

// // const renderPhoto = ({ photo, imageProps }) => (
// function renderPhoto({ photo, imageProps }) {
//    <a
//      href={photo.src}
//      data-fancybox="gallery"
//      data-caption={photo.caption}
//      style={{ display: "block", width: "100%" }}
//    >
//      <img {...imageProps} alt={photo.caption} style={{ width: "100%", height: "auto" }} />
//    </a>
//  };
//  export default renderPhoto;

// function Photo() {
const Photo = () => {

   let [photos,setPhotos]=useState([]);

   const [loading, setLoading] = React.useState(true);

   function getPreloader() {
      // Simulating data fetch
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer); // Cleanup timer
   }

   async function getGallery() {
      fetch('https://vasundharahotel.com/boss/api/showgalleries_api')
      .then((res) => {
        return res.json();
      })
      .then((data) => {

         setPhotos(data.obj);

         console.log(data.obj)

      });

   }

   useEffect(() => {

      getGallery();

      getPreloader();

      
   },[]);


   const images = [
      {
         src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
         width: 500,
         height: 200,
         // isSelected: true,
         caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
         src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
         width: 400,
         height: 212,
         tags: [
            { value: "Ocean", title: "Ocean" },
            { value: "People", title: "People" },
         ],
         alt: "Boats (Jeshu John - designerspics.com)",
      },
      {
         src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
         width: 320,
         height: 300,
      },
   ];

   const renderPhoto = ({ photo, index, onClick }) => (
      <div key={index} style={{ margin: "10px", display: "inline-block" }}>
        <a
          href={photo.src}
          data-fancybox="gallery"
         //  onClick={onClick}
         onClick={()=>{
            alert("Hello");
         }}
          style={{ textDecoration: "none" }}
        >
          {/* <img
            src={photo.src}
            alt={`Photo ${index + 1}`}
            style={{ width: "150px", height: "auto", display: "block" }}
            onClick={()=>{
               alert("Hello");
            }}
            role="button"
          /> */}
          <p style={{ textAlign: "center" }}>Image #{index + 1}</p>
        </a>
      </div>
    );

    
    return (
      <>
      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>

      :

      <div>
         <Header />
         <section class="banner" style={{backgroundImage: "url(assets/img/background.png)"}}>
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-lg-7">
                     <div class="title-area-data">
                     <h2>Gallery</h2>
                     <p>A magical combination that sent aromas to the taste buds</p>
                     </div>
                     {/* <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Photo</li>
                     </ol> */}
                  </div>
                  <div class="col-lg-5">
                     <div class="row">
                        <div class="col-6">
                           <div class="title-area-img">
                              <img alt="title-area-img" src="assets/img/title-area-img-1.jpg" />
                              <img alt="pata" class="pata" src="assets/img/pata.png" />
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="title-area-img two">
                              <img alt="title-area-img" src="assets/img/title-area-img-2.jpg" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <ol class="breadcrumb" style={{marginLeft: '10px'}}>
            <li class="breadcrumb-item">
               <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
            </li>
            {/* <li class="breadcrumb-item active" aria-current="page">Menu</li> */}
            <li class="breadcrumb-item text-white" aria-current="page">Gallery</li>
         </ol>

         {/* <section class="gap"> 
            <div class="container">
               <RowsPhotoAlbum
                  photos={photos}
                  // data-fancybox="gallery"
                  
                  layout= "rows"
                  renderPhoto={renderPhoto}

                  // onClick={(e)=>{
                  //    // alert(photos.src)
                  //    console.log(e.target.value);
                  //    // console.log(photos[index].height)
                  // }}
                  
                  // sizes={{
                  // size: "1168px",
                  // sizes: [
                  //    {
                  //       viewport: "(max-width: 1200px)",
                  //       size: "calc(100vw - 32px)",
                  //    },
                  // ],
                  // }}
               />
            </div>
         </section> */}

         <section class="gap"> 
            <div class="container">
            <div class="row g-4">
               {photos.map((e)=>
               <div class="col-6 col-lg-3">
                <a class="border-1 rounded-5 imgzoomer imgheight" data-fancybox href={e.src}>
                    <img src={e.src} class="imgmaxwidth imgheight rounded-pill-1" />
                </a>
               </div>
               )}
            </div>
            </div>
         </section>

        <Footer />
        </div>
}
      </>
    );
  }
  
  export default Photo;
  