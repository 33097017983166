import React, { useState, useEffect }  from 'react';
import {Link,useParams} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';
// import {useParams} from 'react-router-dom';


import $ from 'jquery';

function Menu() {
   const {category_slug} = useParams();

   const payload = {
      category_slug: category_slug
    };


   let [catwiseproduct, setCatwiseproduct] = useState([]);
   let [catimage, setCatimage] = useState("");
   let [catname, setCatname] = useState("");

   async function getCatwiseproduct() {
      setCatwiseproduct([]);
      setCatimage("");
      setCatname("");
     fetch('https://vasundharahotel.com/boss/api/categoryslugbasedproduct_api',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'  // Set content type to JSON
      },
      body: JSON.stringify(payload)  // Convert parameters to a JSON string
     })
       .then((res) => {
         return res.json();
       })
       .then((data) => {
         
         setCatwiseproduct(data.item);
         setCatimage(data.category_image);
         setCatname(data.category_name);
 
         console.log("========>",data);
         console.log("===>",data.item.category_image);
       });

      //  console.log("===>",catwiseproduct.category_image);
 
      
   }

   const [loading, setLoading] = React.useState(true);

   function getPreloader() {
      // Simulating data fetch
      const timer = setTimeout(() => {
         setLoading(false);
         
      }, 500);
      return () => clearTimeout(timer); // Cleanup timer

      
      
   }

   function getScrollDiv() {
      setTimeout(()=>{
         var scrolldivid=$("#categorywiseprodshow");
         var offsetTop = scrolldivid.offset().top; //-(jQuery(window).height()/2);
         $('html, body').animate({
            scrollTop: offsetTop
         }, 10);
      },100);
   }

   useEffect(() => {
     getCatwiseproduct();

   //   getPreloader();

     getScrollDiv();
      

      

   }, [category_slug]);

    return (
      <>
      <Head />

      {/* {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>
      :

      <div> */}
<Header />
<section class="banner" style={{backgroundImage: "url(assets/img/background.png)"}}>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-lg-7">
            <div class="title-area-data">
            <h2>Menu</h2>
            <p>A magical combination that sent aromas to the taste buds</p>
            </div>
            {/* <ol class="breadcrumb">
               <li class="breadcrumb-item">
                 <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
               </li>
               <li class="breadcrumb-item active" aria-current="page">Menu</li>
            </ol> */}
         </div>
         <div class="col-lg-5">
            <div class="row">
               <div class="col-6">
                  <div class="title-area-img">
                     <img alt="title-area-img" src="assets/img/title-area-img-1.jpg" />
                     <img alt="pata" class="pata" src="assets/img/pata.png" />
                  </div>
               </div>
               <div class="col-6">
                  <div class="title-area-img two">
                     <img alt="title-area-img" src="assets/img/title-area-img-2.jpg" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

{/* {catwiseproduct.map((cwiseproduct) =>
   <div key={cwiseproduct.item_id} >
      <div className='p-2'>
      {cwiseproduct.item_image}-
      {cwiseproduct.item_name} -
      {cwiseproduct.item_description} -
      {cwiseproduct.item_price} -
      {cwiseproduct.offer_price}
      </div>
   </div>
)} */}

   <ol class="breadcrumb" style={{marginLeft: '10px'}}>
      <li class="breadcrumb-item">
         <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
      </li>
      {/* <li class="breadcrumb-item active" aria-current="page">Menu</li> */}
      <li class="breadcrumb-item text-white" aria-current="page">Menu</li>
   </ol>

<section class="gap">
   <div class="container" id="categorywiseprodshow">   
      <div class="row align-items-center discover-menu">
         <div class="col-xl-6">
            <div class="discover-img">
               {/* <img alt="discover" src="assets/img/image2.png" /> */}
               {catimage!=""?<img alt="discover" src={"https://vasundharahotel.com/boss/upload_category/"+catimage+"?id=2"} />:''}
            </div>
         </div>
         <div class="col-xl-5">
            <div class="discover">
               <h4>{category_slug}</h4>
               <ul>
               {catwiseproduct.map((cwiseproduct) =>   
                  <li  key={cwiseproduct.item_id}>
                     <div>
                        <h6>{cwiseproduct.item_name}</h6>
                        <p> {cwiseproduct.item_description}</p>
                     </div>
                     <span>&#8377;{cwiseproduct.item_price}</span>
                  </li>
                   )}
                  {/* <li>
                     <div>
                        <h6>Mocha Whipped Cream</h6>
                        <p>Bacon / Shrimp / Garlic</p>
                     </div>
                     <span>$16.00</span>
                  </li>
                  <li>
                     <div>
                        <h6>Cold Coffee</h6>
                        <p>Pork / Tomatoes / Veggies</p>
                     </div>
                     <span>$34.00</span>
                  </li>
                  <li>
                     <div>
                        <h6>Caramel Macchiato</h6>
                        <p>Prawn / Sausage / Totatos</p>
                     </div>
                     <span>$40.00</span>
                  </li> */}
               </ul>
            </div>
         </div>
      </div>
  
   </div>
</section>

<section>
   <div class="container pb-3">
      <div class="reserve-table reserve-table-bg">
         <div class="row">
            <div class="col-xl-12">
               <div class="reserve-table-text text-center">
                  <h3>Order for Purchase Item</h3>
                  <p><a href="tel:+917044657737" class="button" type="submit">Phone on Call</a></p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

        <Footer />
        {/* </div> */}
{/* } */}
      </>
    );
  }
  
  export default Menu;
  