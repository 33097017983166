
import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';


function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblemenu, setIsVisiblemenu] = useState(false);
  const [isVisiblegal, setIsVisiblegal] = useState(false);

  // Toggle visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Toggle visibility Menu
  const toggleVisibilitymenu = () => {
    setIsVisiblemenu(!isVisiblemenu);
  };

  // Toggle visibility Gallery
  const toggleVisibilitygal = () => {
    setIsVisiblegal(!isVisiblegal);
  };


  let [category, setCategory] = useState([]);
  async function getCategory() {
    fetch('https://vasundharahotel.com/boss/api/category')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setCategory(data.category);

        //console.log("========>",data);
      });

     
  }

  

  useEffect(() => {
    getCategory();

    // getPreloader();

  }, []);



    return (
      <>
      {/* CSS only */}
      {/* <link rel="stylesheet" type="text/css" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
      <link rel="stylesheet" href="assets/css/owl.theme.default.min.css" />

      <link rel="stylesheet" href="assets/css/jquery.fancybox.min.css" />

      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />

      <link rel="stylesheet" href="assets/css/style.css" />
  
      <link rel="stylesheet" href="assets/css/responsive.css" />

      <link rel="stylesheet" href="assets/css/color.css" />

      <script src="assets/js/jquery-3.6.0.min.js" />
      <script src="assets/js/preloader.js" />   */}

      
       
      <header className="one">
        <div className="bottom-bar ">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-xl-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="logo">
                          <Link to="/">
                              <img alt="logo" src="assets/img/logo.png" />
                          </Link>
                        </div>
                        <div className="d-flex cart-checkout">                    
                          <div className="bar-menu" onClick={toggleVisibility}>
                              <i className="fa-solid fa-bars"></i>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <nav className="navbar">
                        <ul className="navbar-links">
                          <li className="navbar-dropdown">
                            <Link to="/">Home</Link>                      
                          </li>
                          <li className="navbar-dropdown">
                            <Link to="/about">About Us</Link>
                          </li>
                          <li className="navbar-dropdown">
                            <Link to="">Menus</Link>
                            <div className="dropdown">
                            {category.map((cat) =>
                                <div key={cat.category_id} >
                                  <Link to={'/'+cat.category_slug}>{cat.category_name}</Link>
                                </div>
                              )}
                            </div>
                          </li>
                          <li className="navbar-dropdown">
                            <Link to="">Media</Link>
                            <div className="dropdown">
                              <Link to="/photo">Gallery</Link>
                              <Link to="/video">Video</Link>                        
                            </div>
                          </li>
                          <li className="navbar-dropdown">
                            <Link to="/review">Review</Link>
                          </li>
                        
                          
                        </ul>
                      </nav>
                  </div>
                  <div className="col-lg-3">
                    <div className="hamburger-icon">              
                          <Link to="/contact" className="button">Contact Us</Link>
                    </div>
                  </div>
              </div>
        </div>  
        </div>
        {isVisible &&
        
        <div className="mobile-nav hmburger-menu d-block open" id="mobile-nav" style={{width: '90%'}}>
        <div className="m-logo">
          <Link to="/">
            <img src="assets/img/logo.png" alt="Responsive Logo" className="img-fluid" />
          </Link>
        </div>
        <div className='d-flex cart-checkout'>
          <div className='navBar' onClick={toggleVisibility}>
           <i className="fas fa-times"></i>
         </div>
         </div>
          <ul>

            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li className="menu-item-has-children" onClick={toggleVisibilitymenu}>
              <Link to={''}>Menu</Link>
              {isVisiblemenu && 
              <ul className="sub-menu2">
                {category.map((cat) =>
                  <li key={cat.category_id}>
                    <Link to={'/'+cat.category_slug} onClick={()=>{
                      setIsVisiblemenu(!isVisiblemenu);
                      setIsVisible(!isVisible);
                    }}>{cat.category_name}</Link>
                  </li>
                )}
                {/* <li><Link to="/menu">menu 1</Link></li>
                <li><Link to="/menu">menu 2</Link></li>
                <li><Link to="/menu">menu 3</Link></li> */}
              </ul>
              }
            </li>
            <li className="menu-item-has-children" onClick={toggleVisibilitygal}>
             <Link to={''}>Media</Link>
             {isVisiblegal && 
              <ul className="sub-menu2">
                <li><Link to="/photo">Gallery</Link></li>
                <li><Link to="/video">Video</Link></li>
              </ul>
              }
            </li>            
            <li><Link to="/contact">Contact</Link></li>
            <li>
              <Link to="/review">Review</Link>
            </li>
            </ul>
            {/* <a href="JavaScript:void(0)" id="res-cross">              
            </a> */}
    </div>
     
        
        }

       
      </header>
  
      </>
    );
  }
  
  export default Header;
  