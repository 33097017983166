import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PageReview from '../inc/PageReview';

const customarsay = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function About() {

   const [loading, setLoading] = React.useState(true);

   function getPreloader() {
      // Simulating data fetch
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer); // Cleanup timer
   }

   useEffect(() => {

      getPreloader();
   },[]);
    return (
      <>
      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>
      :

      <div>
<Header />
<section class="banner" style={{backgroundImage: "url(assets/img/background.png)"}}>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-lg-7">
            <div class="title-area-data">
               <h2>About Us</h2>
               <p>A magical combination that sent aromas to the taste buds</p>
            </div>
            {/* <ol class="breadcrumb">
               <li class="breadcrumb-item">
                 <a href="index.html"><i class="fa-solid fa-house"></i> Home</a>
               </li>
               <li class="breadcrumb-item active" aria-current="page">about</li>
            </ol> */}
         </div>
         <div class="col-lg-5">
            <div class="row">
               <div class="col-6">
                  <div class="title-area-img">
                     <img alt="title-area-img" src="assets/img/title-area-img-1.jpg" />
                     <img alt="pata" class="pata" src="assets/img/pata.png" />
                  </div>
               </div>
               <div class="col-6">
                  <div class="title-area-img two">
                     <img alt="title-area-img" src="assets/img/title-area-img-2.jpg" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

{/* About */}

<ol class="breadcrumb" style={{marginLeft: '10px'}}>
   <li class="breadcrumb-item">
      <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
   </li>
   {/* <li class="breadcrumb-item active" aria-current="page">Menu</li> */}
   <li class="breadcrumb-item text-white" aria-current="page">About</li>
</ol>

<section class="gap_">
   <div class="container">
      <div class="row">
         <div className='col-xl-12 text-center fs-5'>
            <div class="heading-two">
               <div className='h2'>About</div>
               <div class="line"></div>
            </div>
         </div>
         <div class="col-xl-12 text-center">
            <div className="heading py-2">
               <h4>The Vasundhara Hotel and Restaurant</h4>
            </div>
            <div className="about-text">
               <p>At Vasundhara Family Restaurant, we believe in building long-term relationships with our clients. Our all members are passionate about managing services that people love to use.</p>
            </div>
         </div>
      </div>
      <div class="row mt-5">
         <div class="col-xl-4 col-lg-6">
            <div class="restaurant-card">
               <img alt="Restaurant-img" class="w-100" src="assets/img/restaurant.jpg" />
               <div class="restaurant-span">
                  <span>Restaurant</span>
               </div>
               <div class="coctail-bar">
                  <h5>Restaurant</h5>
                  <p>Nisl quam nestibulum ac quam nec aucan ligula. Orci varius natoque li um ac quam nec odio rbine.</p>
                  {/* <a href="#">Order on Call</a> */}
               </div>
            </div>
         </div>
         <div class="col-xl-4 col-lg-6">
            <div class="restaurant-card coctail">
               <img alt="Restaurant-img" class="w-100" src="assets/img/hotel.jpg" />
               <div class="restaurant-span">
                  <span>Hotel</span>
               </div>
               <div class="coctail-bar">
                  <h5>Hotel</h5>
                  <p>Nisl quam nestibulum ac quam nec aucan ligula. Orci varius natoque li um ac quam nec odio rbine.</p>
                  {/* <a href="#">Order on Call</a> */}
               </div>
            </div>
         </div>
         <div class="col-xl-4 col-lg-6">
            <div class="restaurant-card">
               <img alt="Restaurant-img" class="w-100" src="assets/img/private-dining.jpg" />
               <div class="restaurant-span">
                  <span>Private Dining</span>
               </div>
               <div class="coctail-bar">
                  <h5>Private Dining</h5>
                  <p>Nisl quam nestibulum ac quam nec aucan ligula. Orci varius natoque li um ac quam nec odio rbine.</p>
                  {/* <a href="contact.html">Reserve a Table</a> */}
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

        
        {/* <h2>About</h2> */}

{/* Meet Our Team */}

<section class="gap no-top">
   <div class="container">
      <div class="heading-two">
         <h2>Meet Our Experts</h2>
         <div class="line"></div>
      </div>
      <div class="row">
         <div class="col-xl-4 col-lg-6">
            <div class="chef">
               <img alt="cook chef" src="assets/img/chef-1.png" />
               <div class="chef-text d-none">
                  <div>
                     <span>Dessert Specialist</span>
                     <h3>Somnath Dutta</h3>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-4 col-lg-6">
            <div class="chef">
               <img alt="cook chef" src="assets/img/chef-2.png" />
               <div class="chef-text d-none">
                  <div>
                     <span>Chef Master</span>
                     <h3>Rajib Karmakar</h3>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-4 col-lg-6">
            <div class="chef mb-0">
               <img alt="cook chef" src="assets/img/chef-3.png" />
               <div class="chef-text d-none">
                  <div>
                     <span>Dessert specialist</span>
                     <h3>Room Minal</h3>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>


 {/* Testimonials & Reviews */}
 <PageReview />


        <Footer />

        </div>
}
      </>
    );
  }
  
  export default About;
  